import React, { useState, useEffect, useRef } from 'react';
import './App.scss';
// import 'antd/dist/antd.css';
// import "antd/dist/antd.min.css";
import 'antd/dist/reset.css';
// import 'mdb-react-ui-kit/dist/css/mdb.min.css';
// import "@fortawesome/fontawesome-free/css/all.min.css";

import { textContent, paperCategory, newsCategory, experienceCategory, dataArtAwardCategory } from './types/data.global';
import _ from 'lodash';
// import styled from "styled-components";
import award from './assets/images/2-research/award.png';
import yinzhangUrl from './assets/images/1-about/favicon.png';
import maskUrl from './assets/images/9-mask/mask5.svg';
import cvUrl from './assets/images/1-about/yifang_cv_202407.pdf'

import Artwork from './components/Artwork';

import { Modal } from 'antd';
import { Carousel, Tooltip } from 'antd';
import { ConfigProvider } from 'antd';
import { MenuProps } from 'antd';
import { Layout, Anchor, theme } from 'antd';
import { Tabs } from 'antd';
import { TabsProps } from 'antd';
import { Image } from 'antd';
import { Avatar, List } from 'antd';
import { Row, Col } from 'antd';
import { Menu, Drawer, Button } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse, Divider } from 'antd';

// import { slide as MenuBurger } from 'react-burger-menu';

import {
  MDBContainer,
  MDBCollapse,
  MDBNavbar,
  MDBNavbarToggler,
  MDBIcon,
  MDBBtn,
} from 'mdb-react-ui-kit';


import {
  FilePdfFilled,
  FilePptFilled,
  FileTextFilled,
  VideoCameraFilled,
  LayoutFilled,
  MenuOutlined,
} from '@ant-design/icons';

import MyPanel from './components/MyPanel';
const { Header, Content, Footer } = Layout;
const { Panel } = Collapse;

function App() {
  // const {
  //   token: { colorBgContainer },
  // } = theme.useToken()

  // 2. Research Page
  const onChange = (key: string) => {
    console.log(key)
  }
  const researchTabElementList: TabsProps['items'] = []
  _.forEach(paperCategory, (category) => {
    // console.log(category)
    const paperList = textContent.researchPage.filter((paperItem) => paperItem.category.includes(category))
    const paperElementList = paperList.map((paperInfo, textIdx: number) => {
      const authorElementList: JSX.Element[] = []
      _.forEach(paperInfo.authorList, (author, idx: number) => {
        if (author === 'Yifang Wang' || author === 'Yifang Wang*') {
          authorElementList.push(<span className='author-highlight' key={`${textIdx}-${idx}-${author}-highlight`} id={`${textIdx}-${idx}-${author}`}>{author}</span>)
        } else {
          authorElementList.push(<span key={`${textIdx}-${idx}-${author}-common`} id={`${textIdx}-${idx}-${author}`}>{author}</span>)
        }
        if (idx < paperInfo.authorList.length - 1) {
          authorElementList.push(<span key={`${textIdx}-${idx}-${author}-comma`} id={`${textIdx}-${idx}-${author}`}>, </span>)
        }
      })
      // const authorElementList: JSX.Element[] = []
      // _.forEach(paperInfo.authorList, (author, idx: number) => {
      //   if (author === 'Yifang Wang') {
      //     authorElementList.push(<span className='author-highlight'>{author}</span>)
      //   } else {
      //     authorElementList.push(<span>{author}</span>)
      //   }
      //   if (idx < paperInfo.authorList.length - 1) {
      //     authorElementList.push(<span>, </span>)
      //   }
      // })
      return (
        <div className='paper-unit-div' key={paperInfo.title}>
          <div className='paper-teaser'>
            <div className='img-div'>
              {/* <img src={paperInfo.teaser} alt={paperInfo.venueShort} /> */}
              <div className='img-div-wrapper'>
                    <Image
                      src={paperInfo.teaser}
                      alt={paperInfo.venueShort}
                      placeholder={
                        <Image
                          preview={false}
                          src={paperInfo.teaser}
                        />
                      }
                    />
              </div>
            </div>
          </div>
          <div className='text-info'>
            <div className='title'>{paperInfo.title}</div>
            <div className='author'>{authorElementList}</div>
            <div className='venue'>
              <span className='venue-short'>{paperInfo.venueShort}</span> ({paperInfo.venue})
            </div>
            { (paperInfo.award !== '') && 
              <div className='paper-award-div'>
                <div className='wrapper'>
                  <div className='icon'><img src={award} alt={'logo'}/></div> 
                  <div className='text'>{paperInfo.award}</div>
                </div>
              </div> 
            }
            <div className='supp'>[{paperInfo.suppMaterialList.map((suppItem, idx) => {
              let hrefElement = <></>
              if (idx === 0) {
                if (paperInfo.suppMaterialList.length > 1) {
                  hrefElement = <a key={idx} href={suppItem.fileLink}>{suppItem.name} |</a>
                } else {
                  hrefElement = <a key={idx} href={suppItem.fileLink}>{suppItem.name}</a>
                }
              }
              else if (idx < paperInfo.suppMaterialList.length - 1) {
                hrefElement = <a key={idx} href={suppItem.fileLink}> {suppItem.name} |</a>
              } else {
                hrefElement = <a key={idx} href={suppItem.fileLink}> {suppItem.name}</a>
              }

              let iconElement = <></>
              if (suppItem.name === 'PDF') {
                iconElement = <FilePdfFilled />
                // iconElement = <img src={pdfIconUrl} alt='pdf-icon'/>
              } else if (suppItem.name === 'Demo Video' || suppItem.name === 'Background Video' || suppItem.name === 'Case Video' || suppItem.name.includes('Talk')) {
                iconElement = <VideoCameraFilled />
                // iconElement = <img src={videoIconUrl} alt='video-icon'/>
              } else if (suppItem.name.includes('Slides')) {
                iconElement = <FilePptFilled />
                // iconElement = <img src={pptIconUrl} alt='ppt-icon'/>
              } else if (suppItem.name === 'Project Page' || suppItem.name === 'Official Website') {
                iconElement = <LayoutFilled />
                // iconElement = <img src={websiteIconUrl} alt='website-icon'/>
              } else if (suppItem.name === 'Supp Material' || suppItem.name === 'ICSSI2023 Poster') {
                iconElement = <FileTextFilled />
                // iconElement = <img src={websiteIconUrl} alt='website-icon'/>
              }

              return (<div className='supp-unit-div'>
                {iconElement}
                {hrefElement}
              </div>)
            })}]</div>
          </div>
        </div>
      )
    })
    researchTabElementList.push({
      key: category,
      label: category,
      children: paperElementList,
    })
  })

  // 3. News Page
  const newsTabElementList: TabsProps['items'] = []
  _.forEach(newsCategory, (category) => {
    const newsList = textContent.newsPage.filter((newsItem) => newsItem.category.includes(category))
    const newsElementList = newsList.map(newsInfo => {
      let linkElement = <></>
      if (newsInfo.link) {
        if (newsInfo.link.length > 1) {
          const linkList: JSX.Element[] = []
          _.forEach(newsInfo.link, (item) => linkList.push(<a href={item}>[Link]</a> ))
          linkElement = <div className='content'>
            {newsInfo.content}&nbsp;
            {newsInfo.location}&nbsp;
            {linkList}
          </div>
        } else {
          linkElement = <div className='content'>
            {newsInfo.content}&nbsp;@{newsInfo.location}. <a href={newsInfo.link[0]}>[Link]</a>
          </div>
        }
      } else {
        linkElement = <div className='content'>
          {newsInfo.content}&nbsp;
          {newsInfo.location}&nbsp;
        </div>
      }
      return (
        <div className='news-unit-div' key={newsInfo.content}>
          <div className='date'>{newsInfo.date}</div>
          {newsInfo.img && 
            <div className='news-teaser'>
              { (newsInfo.img).map(imgUrl => 
                  // <img src={imgUrl} alt='' />
                  <div className='img-div'>
                    <Image
                      src={imgUrl}
                      placeholder={
                        <Image
                          preview={false}
                          src={imgUrl}
                        />
                      }
                    />
                  </div>
                )}
            </div>}
          {linkElement}
        </div>
      )
    })
    newsTabElementList.push({
      key: category,
      label: category,
      children: newsElementList,
    })
  })


  // art: 再加：点击照片以后，出现作品创作简介。有些是对社会的观察。有些是朋友猫猫的去世。中英文。
  // 4. Data Art Page (https://isamatov.com/react-grid-layout-tutorial/)
  // scroll
  const galleryRef = useRef(null)
  useEffect(() => {
    const handleScroll = (event: React.WheelEvent) => {
      if (galleryRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = galleryRef.current;
        const atEnd = scrollLeft + clientWidth >= scrollWidth;
        const atStart = scrollLeft <= 0;
  
        if (event.deltaY > 0 && !atEnd) {
          // Scrolling right
          event.preventDefault();
          // @ts-ignore
          galleryRef.current.scrollLeft += Math.abs(event.deltaY);
        } else if (event.deltaY < 0 && !atStart) {
          // Scrolling left
          event.preventDefault();
          // @ts-ignore
          galleryRef.current.scrollLeft -= Math.abs(event.deltaY);
        }
        // Allow vertical scrolling if at the start or end
      }
    };
  
    const galleryElement = galleryRef.current;
    if (galleryElement) {
      // @ts-ignore
      galleryElement.addEventListener('wheel', handleScroll, { passive: false });
    }
  
    // Cleanup the event listener on unmount
    return () => {
      if (galleryElement) {
        // @ts-ignore
        galleryElement.removeEventListener('wheel', handleScroll);
      }
    };
  }, []);

  const imageElementList: JSX.Element[] = textContent.dataArtPage.map((item, textIdx: number) => <Artwork key={textIdx} item={item} textIdx={textIdx} />)

  // 4. experience
  const experienceTabElementList: TabsProps['items'] = []
  _.forEach(experienceCategory, (category) => {
    const experienceList = textContent.experiencePage.filter((experienceItem) => experienceItem.category.includes(category))
    let experienceElementList: JSX.Element[] = []
    if (category === experienceCategory.EXPERIENCE) {
      experienceElementList = experienceList.map(experienceInfo => {
        return (
          <div className='experience-unit-div' key={experienceInfo.title}>
            <div className='logo'>
              <div className='img-div' style={{border: 'none'}}>
                <img src={experienceInfo.institutionLogo} alt={experienceInfo.title} />
              </div>
            </div>
            <div className='text-info'>
              <div className='date'>{experienceInfo.timePeriod}</div>
              <div className='role'>
                <span>{experienceInfo.role}, </span>
                <span>with {experienceInfo.supervisor}</span>
              </div>
              <div className='institution'>{experienceInfo.institute}</div>
              <div className='location'>{experienceInfo.location}</div>
            </div>
          </div>
        )
      })
    } else if (category === experienceCategory.SERVICE) {
      experienceElementList = experienceList.map(experienceInfo => {
        // const serviceList = experienceInfo.venueList.map
        return (
          <div className='experience-unit-div'>
            <div className='text-info-service' style={{width: '100%'}}>
              <div className='role-type'>{experienceInfo.role}</div>
              <div>
                <List
                  itemLayout="horizontal"
                  dataSource={experienceInfo.venueList}
                  renderItem={(item, index) => (
                    <List.Item>
                      <List.Item.Meta
                        // title={<a href="https://ant.design">{item}</a>}
                        description={item}
                      />
                    </List.Item>
                  )}
                />
              </div>
            </div>
          </div>
        )
      })
    } else {
      experienceElementList = experienceList.map(experienceInfo => {
        return (
          <div className='experience-unit-div'>
            <div className='rd-logo'>
              <div className='img-div'>
                <img src={experienceInfo.teaser} alt={experienceInfo.title} />
              </div>
            </div>
            <div className='text-info'>
              <div className='role'>
                <span>{experienceInfo.title}</span>
              </div>
              <div className='institution'>{experienceInfo.introduction}</div>
            </div>
          </div>
        )
      })
    }
    experienceTabElementList.push({
      key: category,
      label: category,
      children: experienceElementList,
    })
  })

  // 6. life
  function importAll(r: any) {
    return r.keys().map(r);
  }
  let hangzhoPhotoPathList: string[] = importAll(require.context('./assets/images/5-life/1-suhang/', false, /\.(png|jpe?g|svg|JPG|png|PNG)$/))
  const hangzhoPhotoPreviewPathList: string[] = importAll(require.context('./assets/images/5-life/0-preview/1-suhang/', false, /\.(png|jpe?g|svg|JPG|png|PNG)$/))
  hangzhoPhotoPathList = _.sortBy(hangzhoPhotoPathList, itemName => itemName)
  const hangzhouPhotoList = hangzhoPhotoPathList.map((photoPath, photoIdx) => {
    return (
      <div className='photo-unit-div' key={photoPath}>
        <Image
          src={photoPath}
          placeholder={
            <Image
              preview={false}
              src={hangzhoPhotoPreviewPathList[photoIdx]}
              width={'100%'}
            />
          }
        />
      </div>
    )
  })
  let tripPhotoPathList: string[] = importAll(require.context('./assets/images/5-life/2-others/', false, /\.(png|jpe?g|svg|JPG|png|PNG)$/))
  const tripPhotoPreviewPathList: string[] = importAll(require.context('./assets/images/5-life/0-preview/2-others/', false, /\.(png|jpe?g|svg|JPG|png|PNG)$/))
  tripPhotoPathList = tripPhotoPathList.sort().reverse()// _.sortBy(tripPhotoPathList, itemName => itemName)
  const tripPhotoList = tripPhotoPathList.map((photoPath, idx) => {
    return (
      <div className='photo-unit-div' key={photoPath}>
        <Image
          src={photoPath}
          placeholder={
            <Image
              preview={false}
              src={tripPhotoPreviewPathList[idx]}
              width={'100%'}
            />
          }
        />
      </div>
    )
  })
  let academicPhotoPathList: string[] = importAll(require.context('./assets/images/5-life/3-academic-family/', false, /\.(png|jpe?g|jpg|svg|JPG|png|PNG)$/))
  const academicPhotoPreviewPathList: string[] = importAll(require.context('./assets/images/5-life/0-preview/3-academic-family/', false, /\.(png|jpe?g|jpg|svg|JPG|png|PNG)$/))
  academicPhotoPathList = academicPhotoPathList.sort().reverse()
  const academicPhotoList = academicPhotoPathList.map((photoPath, idx) => {
    return (
      <div className='photo-unit-div' key={photoPath}>
        <Image
          src={photoPath}
          placeholder={
            <Image
              preview={false}
              src={academicPhotoPreviewPathList[idx]}
              width={'100%'}
            />
          }
        />
      </div>
    )
  })



  // 7. responsive component
  const rootSubmenuKeys = ['sub1', 'sub2', 'sub4']
  const [openKeys, setOpenKeys] = useState(['sub1'])
  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  }

  type MenuItem = Required<MenuProps>['items'][number]
  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }
  const items: MenuItem[] = [
    getItem('', 'sub1', <FilePdfFilled />, [
      getItem('Option 1', '1'),
      getItem('Option 2', '2'),
      getItem('Option 3', '3'),
      getItem('Option 4', '4'),
    ]),
    // getItem('Navigation Two', 'sub2', <FilePdfFilled />, [
    //   getItem('Option 5', '5'),
    //   getItem('Option 6', '6'),
    //   getItem('Submenu', 'sub3', null, [getItem('Option 7', '7'), getItem('Option 8', '8')]),
    // ]),
    // getItem('Navigation Three', 'sub4', <FilePdfFilled />, [
    //   getItem('Option 9', '9'),
    //   getItem('Option 10', '10'),
    //   getItem('Option 11', '11'),
    //   getItem('Option 12', '12'),
    // ]),
  ]


  const [isVisible, setIsVisible] = useState(false)
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  }
  
  // const [isFolded, setIsFolded] = useState('none')
  // const [foldedDivHeight, setFoldedDivHeight] = useState(0)
  // const handleFoldingNav = () => {
  //   if (isFolded === 'none') {
  //     setIsFolded('block')
  //     setFoldedDivHeight(200)
  //   } else {
  //     setIsFolded('none')
  //     setFoldedDivHeight(0)
  //   }
  // }

  const showSettings = (event: any) => {
    event.preventDefault()
  }

  const [showNavExternal2, setShowNavExternal2] = useState(false)
  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  }
  const text = `
    A dog is a type of domesticated animal.
    Known for its loyalty and faithfulness,
    it can be found as a welcome guest in many households across the world.
  `;


  // set height
  const [headerHeight, setHeaderHeight] = useState('120px'); // Initial header height
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setHeaderHeight('80px'); // Height when scrolled down
      } else {
        setHeaderHeight('120px'); // Default height
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array means this effect runs once on mount and clean up on unmount


  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#65859a', // https://ant.design/docs/react/customize-theme
          colorSplit: 'rgba(5, 5, 5, 0.06)',
          colorText: '#4e4e4e',
          colorBgLayout: 'white', // '#f5f5f5', // backgournd
        },
      }}
    >
      <Layout>
        <Header style={{ display: 'flex', alignItems: 'center', height: headerHeight }} className='header-div'>
          <Row justify="space-between" style={{width: '96%'}}>
            <Col xs={20} sm={20} md={20} lg={8} className='left-div'>
              <div className='logo'><img src={yinzhangUrl} alt='logo'/></div>
              <div className='text'>YIFANG WANG</div>
            </Col>
            <Col xs={0} sm={0} md={0} lg={16} id='right-div' className='right-div'>
              <div className='right-div-inside'>
                <Anchor
                  direction="horizontal"
                  items={[
                    {
                      key: 'aboutPage',
                      href: '#aboutPage',
                      title: 'ABOUT',
                    },
                    {
                      key: 'researchPage',
                      href: '#researchPage',
                      title: 'RESEARCH',
                    },
                    {
                      key: 'newsPage',
                      href: '#newsPage',
                      title: 'NEWS',
                    },
                    {
                      key: 'dataArtPage',
                      href: '#dataArtPage',
                      title: 'DATA-DRIVEN ART',
                    },
                    {
                      key: 'experiencePage',
                      href: '#experiencePage',
                      title: 'EXPERIENCE',
                    },
                    {
                      key: 'contactPage',
                      href: '#contactPage',
                      title: 'CONTACTS',
                    },
                    {
                      key: 'lifePage',
                      href: '#lifePage',
                      title: 'LIFE',
                    },
                    {
                      key: 'CV',
                      href: cvUrl,
                      title: 'CV',
                    },
                  ]}
                />
              </div>
            </Col>
            <Col xs={4} sm={4} md={4} lg={0} id='right-div-collapse' className='right-div-collapse' onClick={toggleVisibility}>
              <div className='menu'>
                <MenuOutlined />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} className={`right-div-collapse ${isVisible ? 'visible' : 'hidden'}`}>
              <div className={`collapse-menu-div`}>
                <Divider className='collapse-menu-div-unit' plain><a href="#aboutPage">ABOUT</a></Divider>
                <Divider className='collapse-menu-div-unit' plain><a href="#researchPage">RESEARCH</a></Divider>
                <Divider className='collapse-menu-div-unit' plain><a href="#newsPage">NEWS</a></Divider>
                <Divider className='collapse-menu-div-unit' plain><a href="#dataArtPage">DATA-DRIVEN ART</a></Divider>
                <Divider className='collapse-menu-div-unit' plain><a href="#contactPage">CONTACTS</a></Divider>
                <Divider className='collapse-menu-div-unit' plain><a href="#lifePage">LIFE</a></Divider>
                <Divider className='collapse-menu-div-unit' plain><a href={cvUrl}>CV</a></Divider>
              </div>
              {/* <hr className="hidden-md-up"></hr> */}
            </Col>
          </Row>
        </Header>
        <Content className='content-div'>
        {/* style={{ padding: '72px 88px' }}  */}
          <Row justify="space-between" style={{width: '100%'}} className='page-div' id='aboutPage'>
            <Col xs={24} sm={24} md={24} lg={16} className='left-div'>
              <MyPanel viewTitle="HELLO, " width={''} height={'auto'} panelHeaderHeight={72}>
                <div style={{padding: 16}}>
                  <div className='plain-text-div'>{textContent.aboutPage.introduction}</div>
                </div>
              </MyPanel>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} className='left-div'>
              <div className='profile-image-div'>
                  <div className='image-div' style={{padding: 16}}>
                    <img src={textContent.aboutPage.profileImageUrl} className='author-image-class' alt='yifang'
                      style={{
                        WebkitMaskBoxImage: `url(${maskUrl})`,
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                  <div className='short-bio-div'>
                    {/* <a href={cvUrl}>CV</a> | <a href='#contactPage'>Contact Information</a> */}
                    { (textContent.contactPageSM.map(item => {
                      return (
                        <div className='contact-logo-div'>
                          <a href={item.link}>
                            <img src={item.platformLogo} alt={item.platform} />
                          </a>
                        </div>
                      )
                    }))}
                    {/* <List
                      itemLayout="horizontal"
                      dataSource={textContent.contactPage}
                      renderItem={(item) => (
                        <List.Item>
                          <List.Item.Meta
                            avatar={<Avatar src={item.platformLogo} shape="square" />}
                            // title={item.platform}
                            // description={<a href={item.link}>{item.content}</a>}
                          />
                        </List.Item>
                      )}
                    /> */}
                  </div>
              </div>
            </Col>
          </Row>
          <div className='data-art-div' id='dataArtPage' style={{width: '100%'}}>
            <MyPanel viewTitle="DATA-DRIVEN ART" width={'100%'} height={'auto'} panelHeaderHeight={72}>
              <div className='queto'>
                "We have art in order not to die of the truth." &mdash; Friedrich Nietzsche
                {/* 尼采还说过，“我们拥有艺术，为的是不致于亡于真理。”在这里，“真理”一词再次用来指称超感性的所谓“真实世界”，它暗藏着毁灭生命的危险。尼采的“生命”总是指摆脱真理纠缠的那种“上升的生命”。超感性云云，不过是为了诱使生命离开健康活泼的感性和感官世界，使生命力衰弱、枯竭下去……幸亏我们还拥有艺术，才不至于被这样的超感官的真理所毁。有了艺术，超感官的东西才不能使生命衰朽到极度虚亏全面崩溃的田地。
                
                弗里德里希·尼采的名言“我们拥有艺术是为了不死于真相”概括了他关于艺术作为缓冲生活严酷现实的力量的哲学。尼采经常努力解决存在主义问题以及关于人类存在的残酷真相——例如生命缺乏内在意义、痛苦无处不在以及死亡的必然性。这些真相可能极其残酷，可能导致虚无主义或绝望。

                在尼采看来，艺术是一种至关重要的平衡。它允许个人表达自己、解释自己的经历，并以超越现实的方式参与生活。艺术通过为“真相”注入美感、意义和升华，改变了“真相”的严酷性。通过艺术，痛苦或深刻的真相不仅可以忍受，而且可以赋予其深度和共鸣。

                因此，艺术成为生存的必要行为——它使我们不被存在真理的重压所压垮。通过创造或体验艺术，人们可以找到一种与真理共存而不被真理压垮的方法。这是一种形而上的安慰，使生活更加愉快，精神更加丰富。    */}
              </div>
              <div className='data-art-parent-div' 
                ref={galleryRef} 
              >
                {/* {showScrollButtons && (
                  <button className="scroll-button left" onClick={scrollLeft}>
                    &#9664;
                  </button>
                )} */}
                {imageElementList}
                {/* {showScrollButtons && (
                  <button className="scroll-button right" onClick={scrollRight}>
                    &#9654;
                  </button>
                )} */}
              </div>
            </MyPanel>
          </div>
          <div className='page-div' style={{width: '100%'}}>
            <Row justify="space-between" style={{width: '100%'}} className='page-div'>
              <Col xs={24} sm={24} md={15} lg={15} className='left-div'>
                <MyPanel viewTitle="RESEARCH" width={''} height={'auto'} panelHeaderHeight={72}>
                  <div style={{padding: 16}} id='researchPage'>
                    <Tabs defaultActiveKey={paperCategory.ALL} items={researchTabElementList} onChange={onChange} />
                  </div>
                </MyPanel>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} className='left-div'>
                <MyPanel viewTitle="NEWS" width={''} height={'auto'} panelHeaderHeight={72}>
                  <div style={{padding: 16}} id='newsPage' className='news-div'>
                    <Tabs defaultActiveKey={newsCategory.IN2024} items={newsTabElementList} onChange={onChange} />
                  </div>
                </MyPanel>
              </Col>
            </Row>
          </div>
          <div className='page-div' style={{width: '100%'}}>
            <Row justify="space-between" style={{width: '100%'}} className='page-div'>
              <Col xs={24} sm={24} md={15} lg={15} className='left-div'>
                <MyPanel viewTitle="EXPERIENCE, SERVICE, R&Ds" width={''} height={'auto'} panelHeaderHeight={72}>
                  <div style={{padding: 16}} id='experiencePage'>
                    <Tabs defaultActiveKey={newsCategory.ALL} items={experienceTabElementList} onChange={onChange} />
                  </div>
                </MyPanel>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} className='left-div'>
                <MyPanel viewTitle="FOR MORE INFORMATION" width={''} height={'auto'} panelHeaderHeight={72}>
                  <div className='contact-div' id='contactPage'>
                    <List
                      itemLayout="horizontal"
                      dataSource={textContent.contactPage}
                      renderItem={(item) => (
                        <List.Item>
                          <List.Item.Meta
                            avatar={<Avatar src={item.platformLogo} shape="square" />}
                            title={item.platform}
                            description={<a href={item.link}>{item.content}</a>}
                          />
                        </List.Item>
                      )}
                    />
                  </div>
                </MyPanel>
              </Col>
            </Row>
          </div>
          <div className='life-div' id='lifePage'>
            <MyPanel viewTitle="Life" width={'100%'} height={'auto'} panelHeaderHeight={72}>
              <div className='life-patent-div'>
                <div className='gallery'>
                  <div className='photo-list'>
                    {hangzhouPhotoList}
                  </div>
                  <div className='intro'>
                    <p><CaretRightOutlined rotate={-90} /> {textContent.lifePage.hangzhou}</p>
                  </div>
                </div>
                <div className='gallery'>
                  <div className='photo-list'>
                    {tripPhotoList}
                  </div>
                  <div className='intro'>
                    <p><CaretRightOutlined rotate={-90} /> {textContent.lifePage.usa}</p>
                  </div>
                </div>
                <div className='gallery'>
                  <div className='photo-list'>
                    {academicPhotoList}
                  </div>
                  <div className='intro'>
                    <p><CaretRightOutlined rotate={-90} /> {textContent.lifePage.academicFamily}</p>
                  </div>
                </div>
              </div>
            </MyPanel>
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }} className='footer'>
          <div className='footer-div'>
            <div className='left'>
              以一灯传诸灯 终至万灯皆明
              {/* 每个人都有属于自己的一片森林，也许我们从来不曾去过，但它一直在那里，总会在那里， */}
              {/* 迷失的人迷失了 相逢的人会再相逢 */}
            </div>
            <div className='center'>
              Copyright ©  2017- 2024  Yifang Wang
            </div>
            <div className='right'>
              To dream the impossible dream.
              {/* 以一灯传诸灯 终至万灯皆明 */}
            </div>
          </div>
        </Footer>
      </Layout>
    </ConfigProvider>
  )
}


export default App;
